import React, { useState, useEffect } from 'react';
import { Typography, Paper, Grid, Box, TextField } from '@mui/material';
import moment from 'moment';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label
} from 'recharts';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { zhTW } from 'date-fns/locale';
import { getDailyEventAggregation } from '../services/cctvService';
const colorMap = {
  0: '#FF1744', // A400 red
  1: '#D500F9', // A400 purple
  2: '#3D5AFE', // A400 indigo
  3: '#00B0FF', // A400 light blue
  4: '#1DE9B6', // A400 teal
  5: '#76FF03', // A400 light green
  6: '#FFEA00', // A400 yellow
  7: '#FF9100', // A400 orange
  8: '#F5F5F5' // A400 gray
};

const DailyVehicleDetectionChart = ({ teamName, cctvData, date, handleDateChange }) => {
  console.log('cctvData:', [cctvData, date]);
  const [chartData, setChartData] = useState([]);
  const [visibleCameras, setVisibleCameras] = useState({});
  const [cameraNameMap, setCameraNameMap] = useState({});
  const [cameraObjectTypeMap, setCameraObjectTypeMap] = useState({});
  const [cameras, setCameras] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDailyEventAggregation(date, teamName);
        const formattedData = response.vehicleDetectionStats.map((stat) => ({
          time: stat.timeTag,
          ...stat.cameras
        }));
        setChartData(formattedData);
        const initialVisibility = {};
        const nameMap = {};
        const objectTypeMap = {};

        cctvData.forEach((cctv) => {
          initialVisibility[cctv.code] = true;
          nameMap[cctv.code] = cctv.name;
          objectTypeMap[cctv.code] = cctv.object_type; // 添加 object_type 到映射中
        });
        const codes = cctvData.map((cctv) => cctv.code);
        setCameras(codes);
        setVisibleCameras(initialVisibility);
        setCameraNameMap(nameMap);
        setCameraObjectTypeMap(objectTypeMap); // 設置 object_type 映射
      } catch (error) {
        console.error('獲取每日車輛偵測數據時發生錯誤:', error);
      }
    };

    fetchData();
  }, [date, cctvData]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: 'white',
            padding: '10px',
            border: '1px solid #ccc',
            position: 'relative',
            top: '-10px',
            left: '10px'
          }}
        >
          <p>{`時間: ${label}`}</p>
          {payload.map((entry, index) => (
            <p key={index} style={{ color: entry.color }}>
              {`${cameraNameMap[entry.dataKey] || entry.name}: ${entry.value}(${
                cameraObjectTypeMap[entry.dataKey]
              })`}
            </p>
          ))}
          <p style={{ fontWeight: 'bold' }}>
            {`總數: ${payload.reduce((sum, entry) => sum + entry.value, 0)}`}
          </p>
        </div>
      );
    }
    return null;
  };

  const CustomLegend = ({ payload }) => {
    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', mt: 8 }}>
        {payload.map((entry, index) => (
          <Box
            key={`item-${index}`}
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              m: 0.5,
              cursor: 'pointer',
              position: 'relative'
            }}
            onClick={() => toggleCamera(entry.dataKey)}
          >
            <Box
              sx={{
                width: 10,
                height: 10,
                backgroundColor: entry.color,
                mr: 0.5
              }}
            />
            <Typography
              variant="body2"
              sx={{
                position: 'relative',
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: '50%',
                  borderTop: visibleCameras[entry.dataKey] ? 'none' : '1px solid black'
                }
              }}
            >
              {cameraNameMap[entry.dataKey] || entry.value}
            </Typography>
          </Box>
        ))}
      </Box>
    );
  };

  const toggleCamera = (camera) => {
    setVisibleCameras((prev) => {
      // 檢查是否為單一顯示模式
      const isSingleMode = Object.values(prev).filter((v) => v).length === 1 && prev[camera];

      if (isSingleMode) {
        // 如果是單一顯示模式,則顯示所有攝影機
        const allVisible = {};
        cameras.forEach((cam) => {
          allVisible[cam] = true;
        });
        return allVisible;
      }
      // 否則只顯示被點擊的攝影機
      const singleVisible = {};
      cameras.forEach((cam) => {
        singleVisible[cam] = cam === camera;
      });
      return singleVisible;
    });
  };

  return (
    <Paper sx={{ p: 2, height: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end' // 靠右對齊
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={zhTW}>
          <DatePicker
            label="選擇日期"
            value={date}
            onChange={handleDateChange}
            renderInput={(params) => <TextField {...params} variant="standard" />}
          />
        </LocalizationProvider>
      </Box>
      <ResponsiveContainer width="100%" height={450}>
        <BarChart
          barSize={30} // 增加長條寬度
          barGap={2} // 調整長條間距
          data={chartData}
          margin={{ top: 20, right: 30, left: 40, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="time" angle={-45} textAnchor="end" interval={0} dy={10}>
            <Label value="時間(小時)" position="insideBottom" offset={-60} />
          </XAxis>
          <YAxis>
            <Label value="數量(次)" angle={-90} position="insideLeft" dx={-10} />{' '}
            {/* 調整 dx 屬性 */}
          </YAxis>
          <Tooltip content={<CustomTooltip />} />
          <Legend content={<CustomLegend />} />
          {cameras.map((camera, index) => (
            <Bar
              key={camera}
              dataKey={camera}
              stackId="a"
              fill={colorMap[index] || '#e6e6fa'} // 根據index設置顏色
              name={cameraNameMap[camera] || camera}
              hide={!visibleCameras[camera]}
              fillOpacity={1} // 添加這行，設定不透明度為 1
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </Paper>
  );
};

export default DailyVehicleDetectionChart;
