import * as Yup from 'yup';
import { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import axios from 'axios';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import Snackbar from '@mui/material/Snackbar';

import { useDispatch, useSelector } from 'react-redux';
import SelectMenuItem from '../SelectMenuItem';
import { fetchCmsList, setAlertMessage } from '../../actions';
import { getOperationEvent, setFormikObjectRequired, setFormikObjectInitialValues } from './index';
import TTextField from '../TTextField';

import * as ipconfig from '../../config/ipconfig';
// ----------------------------------------------------------------------

export default function CmsForm(props) {
  const {
    cmsID,
    cmsLimitID,
    cmsObjectID,
    name,
    cmsName,
    cmsProductNumber,
    cmsObject,
    cmsContent,
    address,
    latitude,
    longitude,
    description,
    updateTime,
    createTime,
    type,
    enable,
    teamName
  } = props;
  const { setValue } = props;

  // 表單資料來源
  const textFieldLabel = [
    '資訊可變標誌名稱',
    '設備編號',
    '設備產編',
    '緯度',
    '經度',
    '設置地址',
    '描述'
  ];
  const textFieldProps = [
    'cmsName',
    'name',
    'cmsProductNumber',
    'latitude',
    'longitude',
    'address',
    'description'
  ];
  const textFieldValue = [
    cmsName,
    name,
    cmsProductNumber,
    latitude,
    longitude,
    address,
    description
  ];
  const textFieldValuerequired = [
    '請輸入資訊可變標誌名稱',
    '請輸入設備編號',
    '請輸入設備產編',
    '請輸入緯度',
    '請輸入經度',
    '請輸入設置的地址',
    ''
  ];

  const navigate = useNavigate();
  const [open, setSnackbarStateFailed] = useState(false);
  const [open1, setSnackbarStateSuccess] = useState(false);

  const [nackbarVHState, setnackbarVHState] = useState({
    vertical: 'top',
    horizontal: 'center'
  });
  const { vertical, horizontal } = nackbarVHState;

  // 表單不輸入內容相關之訊初始化
  const [formikRequired, setFormikRequired] = useState({});
  const LoginSchema = Yup.object().shape(formikRequired);

  // 表單內容初始化
  const [formikInitialValues, setFormikInitialValues] = useState({});
  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, actions) => onSubmit(values, actions)
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } =
    formik;

  const dispatch = useDispatch();
  const usersStore = useSelector((state) => state.userLogin);
  const { users } = usersStore;

  const [cmsEnable, setCmsEnable] = useState(1);
  const eventContext = useRef(null);

  const [initialState, setInitialState] = useState('first');

  const [cmsObjectData, setcmsObjectData] = useState([]);
  const [cmsObjectlist, setcmsObjectlist] = useState([]);
  const [cmsObjectSelect, setcmsObjectSelect] = useState([]);

  /* ******************************
            initialState
   *********** 資料清除 ***********
        first   第一次進入   
        success 成功        
   ********* 送出前的資料 *********
        error   輸入資料有誤
  ****************************** */
  useEffect(() => {
    if (initialState === 'first' || initialState === 'success') {
      // if(type === 'insert')
      //   setCmsEnable(1)
      // else
      //   setCmsEnable(enable)
      // 設定不輸入會顯示紅字錯誤
      const objRequired = setFormikObjectRequired(textFieldProps, textFieldValuerequired);
      setFormikRequired(objRequired);

      // 設定表單內容的初始值
      const objInitialValues = setFormikObjectInitialValues(textFieldProps, textFieldValue);
      setFormikInitialValues(objInitialValues);

      for (let i = 0; i < textFieldProps.length; i += 1)
        setFieldValue(textFieldProps[i], textFieldValue[i]);

      getCmsObjectData();
    }
  }, [props]);

  const onSubmit = async (values, actions) => {
    actions.setSubmitting(false);
    const today = new Date().toISOString();
    const cmsJson = createCmsJson(values, today);
    const cmsRecord = createCmsReacordJson(values, today);
    const userOperationRecordJson = createUserOperationRecordJson();

    const recordJson = {
      action: type,
      CmsRecord: cmsRecord
    };
    // console.log(userOperationRecordJson);
    // console.log(cmsJson);
    // console.log(recordJson);
    // console.log();

    if (eventContext.current !== '' && eventContext.current !== null) {
      updateCms(userOperationRecordJson, cmsJson, recordJson);
    }

    // // console.log(eventContext.current );

    // if(eventContext.current !== null)
    //   updateCms(userOperationRecordJson,cmsJson,recordJson);
  };

  /* ***************************************
      操作紀錄-事件描述
      1.啟用cms或停用cms
      2.更改MACAddress
      3.更改名稱
      4.更改緯度
      5.更改經度
      6.更改裝置地址
      7.更改描述
  *************************************** */
  const getUserOperationEvent = (values) => {
    eventContext.current = null;
    // console.log(eventContext.current);

    const eventContextList = [
      '資訊可變標誌名稱',
      '設備編號',
      '設備產編',
      '緯度',
      '經度',
      '設置地址',
      '描述',
      '資訊可變標誌推播族群與內容'
    ];
    const objectNameList = [
      values.cmsName,
      values.name,
      values.cmsProductNumber,
      parseFloat(values.latitude),
      parseFloat(values.longitude),
      values.address,
      values.description,
      cmsObjectSelect
    ];
    const oldValuesList = [
      cmsName,
      name,
      cmsProductNumber,
      latitude,
      longitude,
      address,
      description,
      cmsObjectID
    ];

    const eventContextListOfInsert = ['Cms資訊', '描述'];
    const objectNameListOfInsert = [values.cmsName, values.description];
    const oldValuesListOfInsert = [cmsName, description];

    eventContext.current = getOperationEvent(
      cmsEnable,
      enable,
      type,
      eventContextList,
      objectNameList,
      oldValuesList,
      eventContextListOfInsert,
      objectNameListOfInsert,
      oldValuesListOfInsert
    );

    // console.log(eventContext.current);
  };
  // 操作紀錄的JSON
  const createUserOperationRecordJson = () => {
    const userOperationRecordJson = {
      action: type,
      type: 'Cms',
      description: eventContext.current
    };
    // // console.log(userOperationRecordJson);
    return userOperationRecordJson;
  };
  // 最新cms資料的JSON
  const createCmsJson = (values, date) => {
    getUserOperationEvent(values);
    // // console.log(eventContext);
    const jsonObj = {
      cms_id: cmsID,
      cms_limit_id: 2,
      cms_object_id: cmsObjectSelect,
      name: values.name,
      cmsName: values.cmsName,
      cmsProductNumber: values.cmsProductNumber,
      address: values.address,
      description: values.description,
      latitude: parseFloat(values.latitude),
      longitude: parseFloat(values.longitude),
      enable: cmsEnable,
      updateTime: date
    };
    if (type === 'insert') delete jsonObj.cms_id;

    return jsonObj;
  };
  // 舊cms資料的JSON
  const createCmsReacordJson = (values, date) => {
    const cmsRecord = [];

    const oldJsonObj = {
      cms_id: cmsID,
      cms_limit_id: cmsLimitID,
      cms_object_id: cmsObjectID,
      name: name,
      cmsName: cmsName,
      cmsProductNumber: cmsProductNumber,
      address: address,
      description: description,
      latitude: parseFloat(latitude),
      longitude: parseFloat(longitude),
      enable: enable,
      updateTime: updateTime
    };
    if (updateTime !== '') oldJsonObj.updateTime = new Date(updateTime).toISOString();

    if (type === 'update') cmsRecord.push(oldJsonObj);

    const jsonObj = createCmsJson(values, date);

    cmsRecord.push(jsonObj);
    // // console.log(oldTruckJson);
    return cmsRecord;
  };

  // 更新資料
  const getCmsObjectData = async () => {
    // console.log('data')
    await axios
      .get(`https://${ipconfig.apiIP}/api/v1/cmsObject/list`)
      .then(async (response) => {
        // eslint-disable-next-line eqeqeq
        if (response.data.resultCode == 'Success') {
          const data = response.data.rows;
          const list = [];
          // console.log(data)
          setcmsObjectData(data);

          for (let i = 0; i < data.length; i += 1) {
            const objectStr = data[i].object === 'pedestrian' ? '行人' : '駕駛';
            list.push({
              id: data[i].id,
              description: `目標族群 : ${objectStr} ; 提醒內容 : ${data[i].content}`
            });
            if (data[i].id === cmsObjectID) setcmsObjectSelect(cmsObjectID);
          }
          setcmsObjectlist(list);
        }
      })
      .catch((error) => {
        // handle error
        // console.log(error);
      })
      .then(() => {
        // always executed
      });
  };

  // 更新資料
  const updateCms = async (userOperationRecordJson, cmsJson, recordJson) => {
    // console.log(values)
    await axios
      .post(
        `https://${ipconfig.apiIP}/api/v1/${type}/cmsInfo`,
        {
          token: users.token,
          userOperationRecordJson: userOperationRecordJson,
          cmsJson: cmsJson,
          recordJson: recordJson
        },
        {
          header: {
            // Authorization: users.token,
            'Content-Type': 'application/json'
          }
        }
      )
      .then(async (response) => {
        dispatch({ type: 'CHANGE_TOKEN_STATUS', payload: response.data.resultCode });
        // eslint-disable-next-line eqeqeq
        if (response.data.resultCode == 'success') {
          dispatch(setAlertMessage({ open: true, severity: 'success', content: '成功' }));
          dispatch(fetchCmsList({ userName: users.userName, token: users.token, teamName }));
          setValue(0);
          // // console.log("Success");
          // setSnackbarStateSuccess(true);
        }
      })
      .catch((error) => {
        // handle error
        // console.log(error);
      })
      .then(() => {
        // always executed
      });
  };

  const handleChangeCmsEnable = (event) => {
    // 選停用cms
    setCmsEnable(parseInt(event.target.value, 10));
  };

  const handleClose = () => {
    setSnackbarStateFailed(false);
    setSnackbarStateSuccess(false);
  };

  return (
    <div>
      <FormikProvider value={formik}>
        <div>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={cmsEnable}
                onChange={handleChangeCmsEnable}
              >
                <FormControlLabel value={1} control={<Radio />} label="啟用" />
                <FormControlLabel value={0} control={<Radio />} label="停用" />
              </RadioGroup>

              {(() => {
                const TextFieldlist = [];
                for (let i = 0; i < textFieldLabel.length; i += 1) {
                  TextFieldlist.push(
                    <TTextField
                      name={textFieldProps[i]}
                      // type={}
                      label={textFieldLabel[i]}
                      getFieldProps={getFieldProps(textFieldProps[i])}
                      error={Boolean(touched[textFieldProps[i]] && errors[textFieldProps[i]])}
                      helperText={touched[textFieldProps[i]] && errors[textFieldProps[i]]}
                    />
                  );
                }
                return TextFieldlist;
              })()}

              <SelectMenuItem
                Option={cmsObjectlist}
                select={cmsObjectSelect}
                setselect={setcmsObjectSelect}
                labelName="CMS推播內容"
                width={400}
              />
            </Stack>

            <Stack spacing={3} style={{ position: 'relative' }} sx={{ mt: 3 }}>
              <LoadingButton
                style={{ position: 'absolute', right: 0 }}
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                確認送出
              </LoadingButton>
            </Stack>
          </Form>
        </div>
        <div>
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            onClose={handleClose}
            message="輸入資料有誤，請重新輸入"
            key={vertical + horizontal}
          />
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open1}
            onClose={handleClose}
            message="修改完成"
            key1={vertical + horizontal}
          />
        </div>
      </FormikProvider>
    </div>
  );
}

// function TextFieldCcomponents(props) {
//   return (

//     <TextField
//       fullWidth
//       autoComplete={props.name}
//       type="text"
//       label={props.label}
//       {...props.getFieldProps}
//       error={props.error}
//       helperText={props.helperText}

//     // {...getFieldProps(props.name)}
//     // error={Boolean(touched.address && errors.address)}
//     // helperText={touched.address && errors.address}
//   />

//   )
// }
