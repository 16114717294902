import React, { useState } from 'react';
import {
  Typography,
  Box,
  Grid,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Button
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import 'moment/locale/zh-tw'; // 引入中文語系
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { getMonthlyEventAggregation } from '../services/readerService';

const HistoricalReaderQuery = ({ readers, teamName }) => {
  const [selectedReader, setSelectedReader] = useState('');
  const [selectedDate, setSelectedDate] = useState(moment());
  const [chartData, setChartData] = useState([]);

  const processChartData = (monthData, selectedDate) => {
    // 取得該月份的天數
    const daysInMonth = selectedDate.daysInMonth();
    const year = selectedDate.year();
    const month = selectedDate.month() + 1;

    // 建立所有日期的映射，預設值為0
    const dailyCount = {};
    for (let i = 1; i <= daysInMonth; i += 1) {
      const dateStr = `${year}-${String(month).padStart(2, '0')}-${String(i).padStart(2, '0')}`;
      dailyCount[dateStr] = 0;
    }

    // 填入實際統計數據
    monthData.forEach((data) => {
      const date = moment(data.date).format('YYYY-MM-DD');
      dailyCount[date] = data.count;
    });

    // 轉換為圖表所需格式
    return Object.entries(dailyCount).map(([date, count]) => ({
      date: moment(date).format('MM/DD'),
      count
    }));
  };

  const fetchReaderHistoryData = async (macAddress, date) => {
    try {
      const month = date.format('YYYY-MM');
      const response = await getMonthlyEventAggregation(macAddress, month);

      if (response.resultCode === 'Success' && Array.isArray(response.rows)) {
        const processedData = processChartData(response.rows, date);
        setChartData(processedData);
        console.log('Processed monthly stats:', processedData);
      } else {
        setChartData([]);
        console.error('Invalid response format:', response);
      }
    } catch (error) {
      console.error('Error fetching monthly stats:', error);
      setChartData([]);
    }
  };

  const handleReaderChange = (readerId) => {
    setSelectedReader(readerId);
    if (readerId) {
      fetchReaderHistoryData(readerId, selectedDate);
    }
  };

  const handleDateChange = (newDate) => {
    const momentDate = moment(newDate);
    setSelectedDate(momentDate);
    if (selectedReader) {
      fetchReaderHistoryData(selectedReader, momentDate);
    }
  };

  const handleDownloadCSV = () => {
    let csvData = [];
    const strdate = moment(selectedDate).format('YYYY-MM');
    const currentReader = readers.find((reader) => reader.leonReaderMACAddress === selectedReader);

    if (chartData.length > 0) {
      csvData = chartData;
    }

    const BOM = '\uFEFF';
    const csvContent =
      BOM +
      [['日期', '偵測數量'], ...csvData.map((item) => [item.date, item.count])]
        .map((row) => row.join(','))
        .join('\n');

    const blob = new Blob([csvContent], {
      type: 'text/csv;charset=utf-8'
    });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${currentReader?.leonReaderName || 'reader'}_${strdate}.csv`;
    link.click();
  };

  return (
    <Paper sx={{ p: 2 }}>
      <Box sx={{ display: 'flex', gap: 2, mb: 2, alignItems: 'center' }}>
        <Typography variant="h6" sx={{ minWidth: 'fit-content' }}>
          讀取器歷史資料查詢
        </Typography>
        <FormControl sx={{ minWidth: 300 }}>
          <InputLabel>選擇讀取器</InputLabel>
          <Select
            value={selectedReader}
            onChange={(e) => handleReaderChange(e.target.value)}
            label="選擇讀取器"
            sx={{ backgroundColor: 'white' }}
          >
            {readers.map((reader) => (
              <MenuItem key={reader.leonReaderMACAddress} value={reader.leonReaderMACAddress}>
                {reader.leonReaderName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="zh-tw">
          <DatePicker
            label="選擇月份"
            views={['year', 'month']}
            value={selectedDate}
            onChange={handleDateChange}
            renderInput={(params) => <TextField {...params} />}
            localeText={{
              previousMonth: '上個月',
              nextMonth: '下個月',
              months: [
                '一月',
                '二月',
                '三月',
                '四月',
                '五月',
                '六月',
                '七月',
                '八月',
                '九月',
                '十月',
                '十一月',
                '十二月'
              ]
            }}
          />
        </LocalizationProvider>
        <Button
          variant="contained"
          onClick={handleDownloadCSV}
          disabled={!selectedReader}
          sx={{ ml: 'auto' }}
        >
          下載 CSV
        </Button>
      </Box>
      <Box sx={{ height: 500, width: '100%', mt: 3 }}>
        {chartData.length > 0 && (
          <BarChart
            width={1200}
            height={400}
            data={chartData}
            margin={{ top: 20, right: 30, left: 50, bottom: 25 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              height={50}
              label={{
                value: '日期',
                position: 'insideBottom',
                offset: -10
              }}
            />
            <YAxis
              width={80}
              label={{
                value: '車輛數量',
                angle: -90,
                position: 'insideLeft',
                offset: 0,
                dy: 50
              }}
            />
            <Tooltip />
            <Legend verticalAlign="top" height={36} />
            <Bar dataKey="count" fill="#8884d8" name="車輛數量" />
          </BarChart>
        )}
      </Box>
    </Paper>
  );
};

export default HistoricalReaderQuery;
