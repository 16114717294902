import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactImageMagnify from 'react-image-magnify';
import { Typography, Box, Grid, Paper, Tabs, Tab } from '@mui/material';
import moment from 'moment';
import MapComponent from '../components/MapComponent';
import MapComponentReader from '../components/MapComponentReader';
import LiveTrafficImage from '../components/LiveTrafficImage';
import { getCctvList } from '../services/cctvService';
import { getReaderList } from '../services/readerService';
import {
  getRouteInfo,
  getRouteList,
  getSpeedColors,
  getTrafficFlow
} from '../services/routeService'; // 導入服務
import AnomalyDetection from '../components/AnomalyDetection';
import AnomalyReaderDetection from '../components/AnomalyReaderDetection';
import CctvImageList from '../components/CctvImageList';
import DailyVehicleDetectionChart from '../components/DailyVehicleDetectionChart';
import DailyReaderTrafficChart from '../components/DailyReaderTrafficChart';
import DailyRouteStatsChart from '../components/DailyRouteStatsChart';
import { apiIP } from '../config/ipconfig';

const Monitoring = () => {
  const { teams } = useSelector((state) => state.teamsInfo);
  const { users } = useSelector((state) => state.userLogin);
  const [images, setImages] = useState([]);
  const [cctvData, setCctvData] = useState([]);
  const [readers, setReaders] = useState([]);
  const [selectedCctv, setSelectedCctv] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [routes, setRoutes] = useState([]); // 新增路由狀態
  const [speedColors, setspeedColors] = useState([]); // 新增路況顏色
  const [trafficflow, settrafficflow] = useState([]); // 新增最近30分鐘路況
  const [tabValue, setTabValue] = useState(0);
  const [cctvDate, setCctvDate] = useState(moment().format('YYYY-MM-DD'));
  const [readerDate, setreaderDate] = useState(moment().format('YYYY-MM-DD'));
  const [routeDate, setRouteDate] = useState(moment().format('YYYY-MM-DD'));
  const [routeInfo, setRouteInfo] = useState([]);

  const POLLING_INTERVAL = 3 * 60 * 1000; // 3分鐘轉換為毫秒

  // 定義標籤設定物件
  const TAB_CONFIG = {
    cctv: {
      id: 0,
      getLabelText: (date) => `每日攝影機偵測次數統計 (${date})`
    },
    reader: {
      id: 1,
      getLabelText: (date) => `每日讀取器車流量統計 (${date})`
    },
    route: {
      id: 2,
      getLabelText: (date) => `每日路段旅行速度與時間統計 (${date})`
    }
  };
  // 共用樣式設定
  const TAB_STYLES = {
    fontSize: '0.9rem',
    fontWeight: 'medium',
    whiteSpace: 'nowrap'
  };

  useEffect(() => {
    const index = teams.findIndex((team) => team.id === users.team_id);
    const teamName = teams[index]?.name;
    fetchCctvData(teamName);
    fetchReaders(teamName);
    fetchRoutes(teamName);

    // 設定定時器
    // const routesInterval = setInterval(() => {
    //   fetchRoutes();
    //   fetchReaders();
    // }, POLLING_INTERVAL);

    // // 清理定時器
    // return () => clearInterval(routesInterval);
  }, []);

  const fetchCctvData = async (teamName) => {
    try {
      // 根據user的team_id取得team_name
      const data = await getCctvList(teamName);
      console.log('CCTV data:', data);

      setCctvData(data);
    } catch (error) {
      console.error('Error fetching CCTV data:', error);
    }
  };
  const fetchRoutes = async (teamName) => {
    try {
      const routeData = await getRouteList(teamName);
      setRoutes(routeData);
      const speedColorsData = await getSpeedColors();
      setspeedColors(speedColorsData);

      const TrafficFlowData = await getTrafficFlow();
      settrafficflow(TrafficFlowData);

      const routeInfoData = await getRouteInfo();
      setRouteInfo(routeInfoData);
    } catch (error) {
      console.error('Error fetching routes:', error);
    }
  };

  const fetchReaders = async (teamName) => {
    try {
      const response = await getReaderList();
      if (response.resultCode === 'Success') {
        setReaders(response.rows.filter((a) => a.county === teamName));
      }
    } catch (error) {
      console.error('Error fetching reader list:', error);
    }
  };
  useEffect(() => {
    // 變更縣市重新抓取資料

    const index = teams.findIndex((team) => team.id === users.team_id);
    const teamName = teams[index]?.name;
    fetchCctvData(teamName);
    fetchReaders(teamName);
    fetchRoutes(teamName);
  }, [users.team_id]);

  useEffect(() => {
    // 初次執行
    // fetchCctvData();
    // 設定定時器
    // const cctvInterval = setInterval(fetchCctvData, POLLING_INTERVAL);
    // 清理定時器
    // return () => clearInterval(cctvInterval);
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleStreamSelect = (cctvInfo) => {
    setSelectedCctv(cctvInfo);
    setSelectedImage(null);
  };

  const handleImageSelect = (image) => {
    const src = `https://${apiIP}/api/v1/cctv/images/${image.location}/${image.camera}/${image.date}/original/${image.filename}`;
    setSelectedImage({ ...image, src });
  };

  const handleCCTVDateChange = (newDate) => {
    setCctvDate(moment(newDate).format('YYYY-MM-DD'));
  };
  const handlereaderDateChange = (newDate) => {
    setreaderDate(moment(newDate).format('YYYY-MM-DD'));
  };
  const handleRouteStatsDateChange = (newDate) => {
    setRouteDate(moment(newDate).format('YYYY-MM-DD'));
  };
  if (users.team_id === 3) {
    return (
      <Box sx={{ flexGrow: 1, p: 3 }}>
        <Grid container spacing={2}>
          {/* 30日資訊可變標誌提醒駕駛次數(攝影機偵測次數) */}
          <Grid item xs={12}>
            <Paper sx={{ height: '380px', p: 2 }}>
              <AnomalyReaderDetection readers={readers} />
            </Paper>
          </Grid>

          {/* 區塊三：地圖和即時影像 */}
          <Grid item xs={12}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>
                即時監控
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Box sx={{ height: '340px', overflow: 'hidden' }}>
                    <MapComponentReader
                      readers={readers}
                      cctvData={cctvData}
                      onStreamSelect={handleStreamSelect}
                      routes={routes}
                      speedColors={speedColors}
                      trafficflow={trafficflow}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Grid container spacing={2}>
        {/* 30日資訊可變標誌提醒駕駛次數(攝影機偵測次數) */}
        <Grid item xs={12}>
          <Paper sx={{ height: '380px', p: 2 }}>
            <AnomalyDetection cctvData={cctvData} />
          </Paper>
        </Grid>

        {/* 區塊三：地圖和即時影像 */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              即時監控
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Box sx={{ height: '340px', overflow: 'hidden' }}>
                  <MapComponent
                    readers={readers}
                    cctvData={cctvData}
                    onStreamSelect={handleStreamSelect}
                    routes={routes}
                    speedColors={speedColors}
                    trafficflow={trafficflow}
                  />
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ height: '800px', overflow: 'hidden' }}>
            <LiveTrafficImage cctvInfo={selectedCctv} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Monitoring;
