import React, { useState, useEffect } from 'react';
import {
  Typography,
  Paper,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label
} from 'recharts';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { zhTW } from 'date-fns/locale';
import { getDailyReaderStats } from '../services/readerService';

const colorMap = {
  ETG小客車: '#CE93D8',
  LEON大車: '#FFAB91',
  ETG大型車輛: '#A5D6A7'
};

const DailyReaderTrafficChart = ({ readerData, date, handleDateChange }) => {
  const [chartData, setChartData] = useState([]);
  const [fetchTimeout, setFetchTimeout] = useState(null);

  const [selectedReader, setSelectedReader] = useState(
    readerData && readerData.length > 0 ? readerData[0].leonReaderMACAddress : '08ED02B005D5'
  );
  const [visibleReaders, setVisibleReaders] = useState({
    ETG小客車: true,
    LEON大車: true,
    ETG大型車輛: true
  });
  const fetchData = async () => {
    try {
      const stats = await getDailyReaderStats(selectedReader, date);
      const formattedData = stats.map((item) => ({
        time: item.timeTag,
        ...item.readers
      }));
      setChartData(formattedData);
    } catch (error) {
      console.error('獲取每日車輛數據時發生錯誤:', error);
    }
  };
  useEffect(() => {
    // 第一次載入執行
    fetchData();

    // 設定定時器，每2分鐘執行一次
    const timer = setTimeout(async function runFetch() {
      await fetchData();
      // 設定下一次執行
      setFetchTimeout(setTimeout(runFetch, 120000)); // 120000ms = 2分鐘
    }, 120000);

    setFetchTimeout(timer);

    // 清理函式
    return () => {
      if (fetchTimeout) {
        clearTimeout(fetchTimeout);
      }
    };
  }, [date, selectedReader]); // 當日期或讀取器改變時重置定時器

  const handleReaderChange = (event) => {
    setSelectedReader(event.target.value);
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: 'white',
            padding: '10px',
            border: '1px solid #ccc',
            position: 'relative',
            top: '-10px',
            left: '10px'
          }}
        >
          <p>{`時間: ${label}`}</p>
          {payload.map((entry, index) => (
            <p key={index} style={{ color: entry.color }}>
              {`${entry.dataKey}: ${entry.value} 輛`}
            </p>
          ))}
          <p style={{ fontWeight: 'bold' }}>
            {`總流量: ${payload.reduce((sum, entry) => sum + entry.value, 0)} 輛`}
          </p>
        </div>
      );
    }
    return null;
  };

  const CustomLegend = ({ payload }) => (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', mt: 8 }}>
      {payload.map((entry, index) => (
        <Box
          key={`item-${index}`}
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            m: 0.5,
            cursor: 'pointer',
            position: 'relative'
          }}
          onClick={() => toggleReader(entry.dataKey)}
        >
          <Box
            sx={{
              width: 10,
              height: 10,
              backgroundColor: entry.color,
              mr: 0.5
            }}
          />
          <Typography
            variant="body2"
            sx={{
              position: 'relative',
              '&::after': {
                content: '""',
                position: 'absolute',
                left: 0,
                right: 0,
                top: '50%',
                borderTop: visibleReaders[entry.dataKey] ? 'none' : '1px solid black'
              }
            }}
          >
            {entry.dataKey}
          </Typography>
        </Box>
      ))}
    </Box>
  );

  const toggleReader = (reader) => {
    setVisibleReaders((prev) => ({
      ...prev,
      [reader]: !prev[reader]
    }));
  };

  return (
    <Paper sx={{ p: 2, height: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 2
        }}
      >
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>選擇讀取器</InputLabel>
          <Select
            value={selectedReader}
            onChange={handleReaderChange}
            label="選擇讀取器"
            size="small"
          >
            {readerData.map((reader) => (
              <MenuItem key={reader.name} value={reader.leonReaderMACAddress}>
                {reader.leonReaderName || reader.address}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={zhTW}>
          <DatePicker
            label="選擇日期"
            value={date}
            onChange={handleDateChange}
            renderInput={(params) => <TextField {...params} variant="standard" />}
          />
        </LocalizationProvider>
      </Box>

      <ResponsiveContainer width="100%" height={450}>
        <BarChart
          barSize={30}
          barGap={2}
          data={chartData}
          margin={{ top: 20, right: 30, left: 40, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="time" angle={-45} textAnchor="end" interval={0} dy={10}>
            <Label value="時間(小時)" position="insideBottom" offset={-60} />
          </XAxis>
          <YAxis>
            <Label value="車流量(輛)" angle={-90} position="insideLeft" dx={-10} />
          </YAxis>
          <Tooltip content={<CustomTooltip />} />
          <Legend content={<CustomLegend />} />
          {Object.keys(colorMap).map((type) => (
            <Bar
              key={type}
              dataKey={type}
              stackId="a"
              fill={colorMap[type]}
              name={type}
              hide={!visibleReaders[type]}
              fillOpacity={1}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </Paper>
  );
};

export default DailyReaderTrafficChart;
