// 在 cctvService.js 或建立新的 routeService.js

import httpClient from '../utils/httpClient';

export const getRouteList = async (teamName) => {
  try {
    const response = await httpClient.get('/truck/list');

    if (response.data.success) {
      const Routearrat = response.data.data.filter((a) => a.county === teamName);
      if (Routearrat.length === 0) {
        return [];
      }
      return Routearrat.map((route) => ({
        id: route.id,
        name: route.routeName,
        distance: route.distance,
        speedLimit: route.speedLimit,
        coordinates: [
          [route.reader1.latitude, route.reader1.longitude],
          [route.reader2.latitude, route.reader2.longitude]
        ],
        readers: {
          start: {
            location: route.reader1.location,
            position: [route.reader1.latitude, route.reader1.longitude]
          },
          end: {
            location: route.reader2.location,
            position: [route.reader2.latitude, route.reader2.longitude]
          }
        }
      }));
    }

    throw new Error('Failed to fetch route data');
  } catch (error) {
    console.error('Error fetching route list:', error);
    throw error;
  }
};
export const getTrafficFlow = async () => {
  try {
    const response = await httpClient.get('/trafficFlow/averagespeed');

    if (response.data.success) {
      const trafficData = response.data.data;

      // 檢查avg_travel_time與speed_ranges是否存在與有效
      if (
        !trafficData.avg_travel_time ||
        Object.keys(trafficData.avg_travel_time).length === 0 ||
        !trafficData.speed_ranges ||
        Object.keys(trafficData.speed_ranges).length === 0
      ) {
        throw new Error('缺少必要的車流資訊');
      }

      return {
        recordCount: trafficData.record_count,
        averageSpeed: trafficData.avg_speed,
        travelTime: {
          seconds: trafficData.avg_travel_time.seconds,
          formatted: trafficData.avg_travel_time.formatted
        },
        latestRecordTime: trafficData.latest_record_time,
        speedRanges: {
          low: trafficData.speed_ranges.low,
          midLow: trafficData.speed_ranges.mid_low,
          midHigh: trafficData.speed_ranges.mid_high,
          high: trafficData.speed_ranges.high
        },
        colors: {
          low: trafficData.colors.low,
          mid: trafficData.colors.mid,
          high: trafficData.colors.high
        }
      };
    }

    throw new Error('無法取得車流資料');
  } catch (error) {
    console.error('取得車流資料時發生錯誤:', error);
    throw error;
  }
};
export const getSpeedColors = async () => {
  try {
    const response = await httpClient.get('/truck/speedcolors');

    if (response.data.success && response.data.data.length > 0) {
      // 取得預設設定（is_default = 1）
      const defaultSetting =
        response.data.data.find((setting) => setting.is_default === 1) || response.data.data[0];

      return {
        id: defaultSetting.id,
        name: defaultSetting.name,
        description: defaultSetting.description,
        ranges: [
          {
            min: 0,
            max: defaultSetting.speed_range_low,
            color: defaultSetting.color_low
          },
          {
            min: defaultSetting.speed_range_mid_low,
            max: defaultSetting.speed_range_mid_high,
            color: defaultSetting.color_mid
          },
          {
            min: defaultSetting.speed_range_high,
            max: Infinity,
            color: defaultSetting.color_high
          }
        ],
        updatedAt: new Date(defaultSetting.updated_at)
      };
    }

    throw new Error('No speed color settings found');
  } catch (error) {
    console.error('Error fetching speed colors:', error);
    throw error;
  }
};
export const getRouteInfo = async (teamName) => {
  try {
    const response = await httpClient.get('/trafficFlow/routeinfo');
    console.log('routeInfo:', response.data);
    if (response.data.success) {
      return response.data.data
        .filter((a) => a.county === teamName)
        .map((route) => ({
          id: route.id,
          name: route.description,
          distance: route.distance,
          direction: route.direction,
          speedLimit: route.speedLimit,
          readers: {
            first: {
              id: route.reader_1,
              name: route.reader1_name
            },
            second: {
              id: route.reader_2,
              name: route.reader2_name
            }
          },
          updateTime: new Date(route.updateTime).toLocaleDateString('zh-TW')
        }));
    }
    return [];
  } catch (error) {
    console.error('Error fetching route info:', error);
    return [];
  }
};
