import { Icon } from '@iconify/react';
import peopleFill from '@iconify/icons-eva/people-fill';
// import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import motoFill from '@iconify/icons-eva/car-outline';
import wrenchIcon from '@iconify/icons-foundation/wrench';
import dashboardIcon from '@iconify-icons/tabler/dashboard';
import AnalysisIcon from '@iconify/icons-eva/bar-chart-outline';
// import Icon1 from './iconPic';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

// 設備管理子選單配置函式
const getEquipmentChildren = (teamId) => {
  const baseChildren = [
    { label: 'Charts', title: '設備連線狀態', path: '/equipment/ConnectionStatus' }
  ];

  const cctvAndCms = [
    { label: 'Charts', title: '智慧辨識攝影機', path: '/equipment/CCTVManage' },
    { label: 'Charts', title: '資訊可變標誌', path: '/equipment/CmsManage' }
  ];

  const reader = [{ label: 'Charts', title: '智慧感知讀取器', path: '/equipment/ReaderManage' }];

  switch (teamId) {
    case 1:
      return [...baseChildren, ...cctvAndCms, ...reader];
    case 2:
      return [...baseChildren, ...cctvAndCms];
    case 3:
      return [...baseChildren, ...reader];
    default:
      return baseChildren;
  }
};

// 完整的選單配置
const getFullSidebarConfig = (teamId) => [
  {
    title: '即時監控',
    path: '/dashboard',
    icon: getIcon(dashboardIcon)
  },
  {
    title: '設備管理',
    path: '/equipment',
    icon: getIcon(wrenchIcon),
    children: getEquipmentChildren(teamId)
  },
  {
    title: '統計分析',
    path: '/AnalysisDashboard',
    icon: getIcon(AnalysisIcon)
  },
  {
    title: '車輛管理',
    path: '/manage',
    icon: getIcon(motoFill),
    children: [
      { label: 'Charts', title: '識別裝置資訊', path: '/manage/leonTagManage' },
      { label: 'Charts', title: '車輛資訊', path: '/manage/truckManage' },
      { label: 'Charts', title: '上傳匯入', path: '/manage/Uploadexcel' }
    ]
  },
  {
    title: '帳號管理',
    path: '/account',
    icon: getIcon(peopleFill),
    children: [
      { label: 'Charts', title: '使用者資訊', path: '/account/userManage' },
      { label: 'Charts', title: '使用者操作紀錄', path: '/account/userOperationRecord' }
    ]
  }
];

// 根據傳入的標題陣列返回對應選單項目
const getSidebarConfig = (titles = [], teamId) => {
  // 如果沒有傳入標題，返回完整選單
  if (!titles || titles.length === 0) {
    return [getFullSidebarConfig(teamId)[0]];
  }

  // 過濾主選單標題
  return getFullSidebarConfig(teamId).filter((item) => titles.includes(item.title));
};

export default getSidebarConfig;
