import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
// material
import { Button, Grid, Tabs, Tab, Typography, Box, Container, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Page from '../components/Page';
import SimpleDialog1 from '../components/authentication/account/ChangePasswordForm';

import { fetchUserList } from '../actions';
import TTable from '../components/TTable';

import { UserForm } from '../components/editData/index';
import { UserInsertForm } from '../components/insertData/index';
import * as config from '../config/config';

const Alert = React.forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

// 定義切換頁
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    style: { 'font-size': '24px' }
  };
}

export default function EnhancedTable() {
  const [headCells, setheadCells] = React.useState([]);
  const headCell = [
    {
      id: 'sequence',
      numeric: true,
      disablePadding: true,
      fillter: false,
      label: '項目',
      align: 'center',
      type: 'render',
      fn: (row) => {
        // 改用 row.sequence
        return (
          <div style={{ textAlign: 'center' }}>
            {row.sequence} {/* 使用預先計算的序號 */}
          </div>
        );
      }
    },
    {
      id: 'user_name',
      numeric: false,
      disablePadding: true,
      fillter: true,
      label: '名稱'
    },
    {
      id: 'enable',
      numeric: false,
      disablePadding: true,
      fillter: true,
      label: '狀態',
      type: 'format',
      fn: (enable) => {
        const enableStr = enable ? '啟用' : '停用';
        return enableStr;
      }
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: true,
      fillter: true,
      label: 'Email'
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: true,
      fillter: true,
      label: '權限'
    },
    {
      id: 'create_time',
      numeric: false,
      disablePadding: false,
      fillter: false,
      label: '創建時間',
      type: 'format',
      fn: (data) => {
        const time = new Date(data).toLocaleString();
        return time;
      }
    },
    {
      type: 'render',
      fn: (row) => {
        const { id, userName, email, enable } = row;
        return (
          <Button
            variant="outlined"
            onClick={() => {
              // alert(leonTagID)
              setInfo(row.team_id, id, row.user_name, email, row.user_group_id, enable);
              // // console.log(leonTagID)
              // setValue(1)
            }}
          >
            編輯
          </Button>
        );
      }
    }
  ];
  // 宣告一個新的 state 變數，我們稱作為「count」。
  // 相似於 componentDidMount 和 componentDidUpdate:
  // 車機清單
  const [Vehiclelist, setVehiclelist] = React.useState([]);
  const [reason, setreason] = React.useState(' ');
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();
  const usersStore = useSelector((state) => state.userLogin);
  const userListStore = useSelector((state) => state.userManage);
  const { users } = usersStore;
  const operatorUserGroupID = users.user_group_id;
  const { userList, error } = userListStore;
  useEffect(() => {
    if (userList.length === 0) {
      Vehicle_management();
      setVehiclelist([]);

      setVehiclelist(userList);
    } else {
      setVehiclelist([]);

      setVehiclelist(userList);
    }
    // console.log(users)
    const list = [];
    for (let i = 0; i < headCell.length; i += 1) {
      if (i === headCell.length - 1 && users.permission !== 'admin') break;
      list.push(headCell[i]);
    }
    setheadCells(list);
  }, [userList, error, users]);

  // 讀取使用者
  // eslint-disable-next-line camelcase
  const Vehicle_management = async () => {
    dispatch(fetchUserList({ userName: users.userName, token: users.token }));
  };

  // 關閉彈跳釋出
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  // tab切換
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [dialogOpen, setDialogOpen] = useState(false);
  const [teamID, setteamID] = useState(0);
  const [userGroupID, setuserGroupID] = useState();
  const [userID, setuserID] = useState();
  const [userName, setuserName] = useState();
  const [email, setemail] = useState();
  const [enable, setEnable] = useState();
  const [type, setType] = useState();

  const setInfo = (teamID, userID, userName, email, userGroupID, enable) => {
    setteamID(teamID);
    setuserID(userID);
    setuserName(userName);
    // setpassword(password);
    setemail(email);
    setuserGroupID(userGroupID);
    setEnable(enable);
    setType('update');
    setValue(1);
  };

  const handleDialogClose = (value) => {
    setDialogOpen(false);
    setType(value);
    if (value !== '') setValue(1);
  };

  const insetForm = () => {
    setteamID('');
    setuserID('');
    setuserName('');
    // setpassword('');
    setemail('');
    setuserGroupID('');
    setEnable(1);
    setType('insert');
    setValue(1);
  };

  const changePwd = () => {
    setDialogOpen(true);
  };

  // Avoid a layout jump when reaching the last page with empty rows.

  return (
    <Page title="智慧管理識別平台">
      <Container maxWidth="xl">
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            {reason}
          </Alert>
        </Snackbar>

        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="使用者資訊" {...a11yProps(0)} />
          {/* <Tab label="詳細資訊" {...a11yProps(1)} /> */}
          {(() => {
            if (users.user_group_id === 1) {
              return <Tab label="詳細資訊" {...a11yProps(1)} />;
            }
          })()}
          {(() => {
            if (users.user_group_id === 1) {
              return (
                <div style={{ marginLeft: 'auto', marginRight: '24px' }}>
                  <Button
                    variant="contained"
                    size="large"
                    style={{ marginLeft: 'auto', marginRight: '24px' }}
                    onClick={changePwd}
                  >
                    修改密碼
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    style={{ marginLeft: 'auto', marginRight: '24px' }}
                    onClick={insetForm}
                  >
                    新增
                  </Button>
                </div>
              );
            }
            return (
              <Button
                variant="contained"
                size="large"
                style={{ marginLeft: 'auto', marginRight: '24px' }}
                onClick={insetForm}
              >
                修改密碼
              </Button>
            );
          })()}
        </Tabs>

        <TabPanel value={value} index={0}>
          <Grid
            item
            xs={12}
            sx={{
              '& th': {
                fontSize: config.tableTitleFontSize
              },
              '& td': {
                fontSize: config.tableContentFontSize
              },
              '& span': {
                fontSize: config.tableContentFontSize,
                height: config.tableSpanHeight
              }
            }}
          >
            <TTable
              Vehiclelist={Vehiclelist.map((item, index) => ({
                ...item,
                sequence: index + 1 // 加入 index 作為序號基礎
              }))}
              headCells={headCells}
            />
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={1}>
          {(() => {
            if (type === 'update') {
              localStorage.setItem('mode', '1');
              return (
                <UserForm
                  operatorUsers={users}
                  teamID={teamID}
                  userID={userID}
                  userName={userName}
                  email={email}
                  userGroupID={userGroupID}
                  enable={enable}
                  type={type}
                  setValue={setValue}
                />
              );
            }
            if (type === 'insert') {
              localStorage.setItem('mode', '1');
              return (
                <UserInsertForm
                  operatorUsers={users}
                  teamID={teamID}
                  userID={userID}
                  userName={userName}
                  email={email}
                  userGroupID={userGroupID}
                  enable={enable}
                  type={type}
                  setValue={setValue}
                />
              );
            }
          })()}
        </TabPanel>
        <SimpleDialog1 open={dialogOpen} onClose={setDialogOpen} />
      </Container>
    </Page>
  );
}
