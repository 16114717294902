import { startOfMonth, endOfMonth } from 'date-fns';
import httpClient from '../utils/httpClient';

export const getCctvList = async (county) => {
  try {
    const response = await httpClient.get('/cctv/list');
    return response.data.filter((a) => a.county === county);
  } catch (error) {
    console.error('Error fetching CCTV data:', error);
    throw error;
  }
};
export const getCctvImages = async (
  cctvCode,
  page,
  limit = 10,
  selectedDate = new Date(),
  teamName
) => {
  try {
    const startDate = startOfMonth(selectedDate);
    const endDate = endOfMonth(selectedDate);
    const response = await httpClient.get(`/cctv/images/${cctvCode}`, {
      params: {
        startDate: startDate.toISOString().split('T')[0],
        endDate: endDate.toISOString().split('T')[0],
        page,
        limit,
        teamName
      }
    });
    console.log('CCTV images cctv/images:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching CCTV images:', error);
    throw error;
  }
};
export const getDailyEventAggregation = async (date, teamName) => {
  try {
    const url = `/cctv/daily-aggregation/${date}`;
    const params = { teamName };
    const response = await httpClient.get(url, { params });
    return response.data;
  } catch (error) {
    console.error('Error fetching daily event aggregation:', error);
    throw error;
  }
};

export const getMonthlyEventAggregation = async (camera, month) => {
  try {
    const response = await httpClient.post(`/cctv/monthly-aggregation/${camera}/${month}`);
    console.log('Monthly event aggregation:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching monthly event aggregation:', error);
    throw error;
  }
};
