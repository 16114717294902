import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactImageMagnify from 'react-image-magnify';
import {
  Typography,
  Box,
  Grid,
  Paper,
  Tabs,
  Tab,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';
import moment from 'moment';
import MapComponent from '../components/MapComponent';
import LiveTrafficImage from '../components/LiveTrafficImage';
import { getCctvList } from '../services/cctvService';
import { getReaderList } from '../services/readerService';
import {
  getRouteInfo,
  getRouteList,
  getSpeedColors,
  getTrafficFlow
} from '../services/routeService'; // 導入服務

import DailyVehicleDetectionChart from '../components/DailyVehicleDetectionChart';
import DailyReaderTrafficChart from '../components/DailyReaderTrafficChart';
import DailyRouteStatsChart from '../components/DailyRouteStatsChart';
import { apiIP } from '../config/ipconfig';
import HistoricalCctvQuery from '../components/HistoricalCctvQuery';
import HistoricalReaderQuery from '../components/HistoricalReaderQuery';

const AnalysisDashboard = () => {
  const { teams } = useSelector((state) => state.teamsInfo);
  const { users } = useSelector((state) => state.userLogin);
  const index = teams.findIndex((team) => team.id === users.team_id);
  const teamName = teams[index]?.name;

  const getDateByConfigId = (configId) => {
    switch (configId) {
      case 'cctv':
        return cctvDate;
      case 'reader':
        return readerDate;
      case 'route':
        return routeDate;
      default:
        return '';
    }
  };
  const [cctvData, setCctvData] = useState([]);
  const [readers, setReaders] = useState([]);
  const [selectedCctv, setSelectedCctv] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [routes, setRoutes] = useState([]); // 新增路由狀態
  const [speedColors, setspeedColors] = useState([]); // 新增路況顏色
  const [trafficflow, settrafficflow] = useState([]); // 新增最近30分鐘路況
  const [tabValue, setTabValue] = useState(0);
  const [cctvDate, setCctvDate] = useState(moment().format('YYYY-MM-DD'));
  const [readerDate, setreaderDate] = useState(moment().format('YYYY-MM-DD'));
  const [routeDate, setRouteDate] = useState(moment().format('YYYY-MM-DD'));
  const [routeInfo, setRouteInfo] = useState([]);
  const [images, setImages] = useState([]);

  const POLLING_INTERVAL = 3 * 60 * 1000; // 3分鐘轉換為毫秒
  const handleCCTVDateChange = (newDate) => {
    setCctvDate(moment(newDate).format('YYYY-MM-DD'));
  };
  const handlereaderDateChange = (newDate) => {
    setreaderDate(moment(newDate).format('YYYY-MM-DD'));
  };
  const handleRouteStatsDateChange = (newDate) => {
    setRouteDate(moment(newDate).format('YYYY-MM-DD'));
  };

  // 根據 users.team_id 動態定義 TAB_CONFIG
  const TAB_CONFIG = Object.fromEntries(
    [
      // CCTV tab
      (users.team_id === 1 || users.team_id === 2) && {
        key: 'cctv',
        config: {
          id: 'cctv',
          index: 0,
          getLabelText: (date) => `每日攝影機偵測次數統計 (${date})`,
          component: (
            <DailyVehicleDetectionChart
              teamName={teamName}
              cctvData={cctvData}
              date={cctvDate}
              handleDateChange={handleCCTVDateChange}
            />
          )
        }
      },
      // Reader tab
      (users.team_id === 1 || users.team_id === 3) && {
        key: 'reader',
        config: {
          id: 'reader',
          index: users.team_id === 3 ? 0 : 1,
          getLabelText: (date) => `每日讀取器車流量統計 (${date})`,
          component: (
            <DailyReaderTrafficChart
              readerData={readers}
              date={readerDate}
              handleDateChange={handlereaderDateChange}
            />
          )
        }
      },
      // Route tab
      (users.team_id === 1 || users.team_id === 3) && {
        key: 'route',
        config: {
          id: 'route',
          index: users.team_id === 3 ? 1 : 2,
          getLabelText: (date) => `每日路段旅行速度與時間統計 (${date})`,
          component: (
            <DailyRouteStatsChart
              routeData={routeInfo}
              date={routeDate}
              handleDateChange={handleRouteStatsDateChange}
            />
          )
        }
      }
    ]
      .filter(Boolean)
      .map(({ key, config }) => [key, config])
  );

  // 共用樣式設定
  const TAB_STYLES = {
    fontSize: '0.9rem',
    fontWeight: 'medium',
    whiteSpace: 'nowrap'
  };

  const fetchCctvData = async (teamName) => {
    try {
      // 根據user的team_id取得team_name
      const data = await getCctvList(teamName);
      console.log('CCTV data:', data);

      setCctvData(data);
    } catch (error) {
      console.error('Error fetching CCTV data:', error);
    }
  };
  const fetchRoutes = async (teamName) => {
    try {
      const routeData = await getRouteList(teamName);
      setRoutes(routeData);
      const speedColorsData = await getSpeedColors();
      setspeedColors(speedColorsData);

      const TrafficFlowData = await getTrafficFlow();
      settrafficflow(TrafficFlowData);

      const routeInfoData = await getRouteInfo(teamName);
      console.log('routeInfoData:', routeInfoData);
      setRouteInfo(routeInfoData);
    } catch (error) {
      console.error('Error fetching routes:', error);
    }
  };

  const fetchReaders = async (teamName) => {
    try {
      const response = await getReaderList();
      if (response.resultCode === 'Success') {
        setReaders(response.rows.filter((a) => a.county === teamName));
        console.log('Readers:', response.rows);
      }
    } catch (error) {
      console.error('Error fetching reader list:', error);
    }
  };
  useEffect(() => {
    // 變更縣市重新抓取資料
    fetchCctvData(teamName);
    fetchReaders(teamName);
    fetchRoutes(teamName);
  }, [users.team_id]);

  const handleTabChange = (event, newValue) => {
    const targetConfig = Object.values(TAB_CONFIG).find((config) => config.index === newValue);
    if (targetConfig) {
      setTabValue(newValue);
    }
  };

  const handleStreamSelect = (cctvInfo) => {
    setSelectedCctv(cctvInfo);
    setSelectedImage(null);
  };

  const handleImageSelect = (image) => {
    const src = `https://${apiIP}/api/v1/cctv/images/${image.location}/${image.camera}/${image.date}/original/${image.filename}`;
    setSelectedImage({ ...image, src });
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Grid container spacing={2}>
        {/* 區塊二：每日車輛偵測統計 */}
        <Grid item xs={12}>
          <Paper sx={{ height: '600px', p: 2 }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
              <Tabs value={tabValue} onChange={handleTabChange} aria-label="車輛偵測統計">
                {Object.values(TAB_CONFIG)
                  .sort((a, b) => a.index - b.index)
                  .map((config) => (
                    <Tab
                      key={config.id}
                      label={config.getLabelText(getDateByConfigId(config.id))}
                      sx={TAB_STYLES}
                    />
                  ))}
              </Tabs>
            </Box>

            {/* 根據 tabValue 顯示對應元件 */}
            {Object.values(TAB_CONFIG)
              .sort((a, b) => a.index - b.index)
              .map((config) => config.index === tabValue && config.component)}
          </Paper>
        </Grid>

        {/* 區塊四：歷史資料查詢和放大圖片  */}
        {users.team_id !== 3 && (
          <Grid item xs={12}>
            <HistoricalCctvQuery
              cctvData={cctvData}
              selectedCctv={selectedCctv}
              handleStreamSelect={handleStreamSelect}
              selectedImage={selectedImage}
              setSelectedImage={setSelectedImage}
              images={images}
              setImages={setImages}
            />
          </Grid>
        )}

        {/* 區塊五：讀取器歷史資料查詢 */}
        {(users.team_id === 1 || users.team_id === 3) && (
          <Grid item xs={12}>
            <HistoricalReaderQuery readers={readers} teamName={teamName} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default AnalysisDashboard;
