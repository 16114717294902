import { call, put, takeEvery } from 'redux-saga/effects';
import { useDispatch, useSelector } from 'react-redux';
import * as types from '../config/types';
import * as ipconfig from '../config/ipconfig';

const apiUrl = `https://${ipconfig.apiIP}/api/v1/reader/list`;

function getApi(parse) {
  return fetch(apiUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(parse)
  })
    .then((res) => res.json())
    .catch((error) => {
      throw error;
    });
}

function* fetchReaderList(action) {
  try {
    console.log('reader');
    const readers = yield call(getApi, action.payload.parse);
    yield put({ type: 'CHANGE_TOKEN_STATUS', payload: readers.resultCode });
    const countyreaders = readers?.rows.filter(
      (reader) => reader.county === action.payload?.parse?.teamName
    );
    readers.rows = countyreaders;

    if (readers.resultCode === 'Failed') yield put(getReadersError());
    else yield put(getReadersSuccess(readers));
  } catch (error) {
    yield put(getReadersError(error.message));
  }
}

export const getReadersSuccess = (readers) => {
  // console.log("request readers success");
  return {
    type: types.GET_READER_LIST_SUCCESS,
    payload: {
      loading: false,
      readers: readers
    }
  };
};

export const getReadersError = () => {
  // console.log("request readers error");
  return {
    type: types.GET_READER_LIST_FAILED,
    error: 'FAILED'
  };
};

function* readerSaga() {
  yield takeEvery(types.GET_READER_LIST_REQUEST, fetchReaderList);
}

export default readerSaga;
