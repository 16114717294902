import React from 'react';
import {
  Typography,
  Box,
  Grid,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';
import ReactImageMagnify from 'react-image-magnify';
import CctvImageList from './CctvImageList';
import { apiIP } from '../config/ipconfig';

const HistoricalCctvQuery = ({
  cctvData,
  selectedCctv,
  handleStreamSelect,
  selectedImage,
  setSelectedImage,
  images,
  setImages
}) => {
  const handleImageSelect = (image) => {
    const src = `https://${apiIP}/api/v1/cctv/images/${image.location}/${image.camera}/${image.date}/original/${image.filename}`;
    setSelectedImage({ ...image, src });
  };

  return (
    <Paper sx={{ p: 2 }}>
      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <Typography variant="h6" sx={{ minWidth: 'fit-content' }}>
          歷史資料查詢
        </Typography>
        <FormControl sx={{ minWidth: 300 }}>
          <InputLabel id="cctv-select-label">選擇攝影機</InputLabel>
          <Select
            labelId="cctv-select-label"
            value={selectedCctv ? selectedCctv.id : ''}
            onChange={(e) => {
              const selected = cctvData.find((cctv) => cctv.id === e.target.value);
              if (selected) handleStreamSelect(selected);
            }}
            label="選擇攝影機"
            sx={{ backgroundColor: 'white' }}
          >
            {cctvData.map((cctv) => (
              <MenuItem key={cctv.id} value={cctv.id}>
                {cctv.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Box sx={{ height: '700px', overflow: 'auto' }}>
            <CctvImageList
              selectedCctv={selectedCctv}
              onImageSelect={handleImageSelect}
              images={images}
              setImages={setImages}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={4} sx={{ display: 'none' }}>
          <Box
            sx={{
              height: '700px',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden'
            }}
          >
            <Typography variant="subtitle1" sx={{ mb: 2, textAlign: 'center' }}>
              {selectedImage ? '放大圖片' : '選擇圖片'}
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {selectedImage ? (
                <ReactImageMagnify
                  {...{
                    smallImage: {
                      alt: 'Selected CCTV',
                      isFluidWidth: true,
                      src: selectedImage.src
                    },
                    largeImage: {
                      src: selectedImage.src,
                      width: 1280,
                      height: 720
                    },
                    enlargedImageContainerDimensions: {
                      width: '200%',
                      height: '200%'
                    }
                  }}
                  style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                />
              ) : (
                <Typography variant="body1">請從左側列表選擇一張圖片</Typography>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default HistoricalCctvQuery;
