import * as Yup from 'yup';
import { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import axios from 'axios';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import Snackbar from '@mui/material/Snackbar';

import { useDispatch, useSelector } from 'react-redux';
import SelectMenuItem from '../SelectMenuItem';
import { fetchUserList, setAlertMessage } from '../../actions';
import { getOperationEvent, setFormikObjectRequired, setFormikObjectInitialValues } from './index';
import TTextField from '../TTextField';
import * as ipconfig from '../../config/ipconfig';
// ----------------------------------------------------------------------

export default function UserForm(props) {
  const { teamID, userID, userName, email, userGroupID, enable, type, operatorUsers } = props;
  const { setValue } = props;
  console.log(operatorUsers);

  // const [password, setpassword] = useState('');

  // // 表單資料來源
  // const textFieldLabel = ["使用者名稱","密碼","email"];
  // const textFieldProps = ["userName","password","email",];
  // const textFieldValue = [userName,password,email,];
  // const textFieldValuerequired = ['請輸入使用者名稱',
  //                                 '請輸入密碼',
  //                                 '請輸入email',];

  const [password, setpassword] = useState('');

  // 表單資料來源
  const textFieldLabel = ['使用者名稱', 'email'];
  const textFieldProps = ['userName', 'email'];
  const textFieldValue = [userName, email];
  const textFieldValuerequired = ['請輸入使用者名稱', ''];

  const navigate = useNavigate();
  const [open, setSnackbarStateFailed] = useState(false);
  const [open1, setSnackbarStateSuccess] = useState(false);

  const [nackbarVHState, setnackbarVHState] = useState({
    vertical: 'top',
    horizontal: 'center'
  });
  const { vertical, horizontal } = nackbarVHState;

  // 表單不輸入內容相關之訊初始化
  const [formikRequired, setFormikRequired] = useState({});
  const LoginSchema = Yup.object().shape(formikRequired);

  // 表單內容初始化
  const [formikInitialValues, setFormikInitialValues] = useState({});
  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, actions) => onSubmit(values, actions)
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } =
    formik;

  const dispatch = useDispatch();
  const usersStore = useSelector((state) => state.userLogin);
  const { users } = usersStore;
  const teamsStore = useSelector((state) => state.teamsInfo);
  const { teams } = teamsStore;
  const userListStore = useSelector((state) => state.userManage);
  const { userList } = userListStore;

  const [teamList, setTeamList] = useState([]);
  const [teamSelect, setTeamSelect] = useState(0);
  const [userEnable, setUserEnable] = useState(0);
  const [oldTeamName, setOldTeamName] = useState(0);
  const teamNameSelect = useRef('');
  const eventContext = useRef(null);
  const labelTeamName = '選擇綁定縣市';

  const [userGroupList, setuserGroupList] = useState([]);
  const [userGroupSelect, setuserGroupSelect] = useState(1);
  const labelUserGroup = '選擇權限';

  const [initialState, setInitialState] = useState('first');

  /* ******************************
            initialState
   *********** 資料清除 ***********
        first   第一次進入   
        success 成功        
   ********* 送出前的資料 *********
        error   輸入資料有誤
  ****************************** */
  useEffect(() => {
    localStorage.setItem('mode', '1');
    if (initialState === 'first' || initialState === 'success') {
      setTeamSelect(users.team_id);
      if (type === 'insert') setUserEnable(1);
      else setUserEnable(enable);
      // 設定不輸入會顯示紅字錯誤
      const objRequired = setFormikObjectRequired(textFieldProps, textFieldValuerequired);
      setFormikRequired(objRequired);

      // 設定表單內容的初始值
      const objInitialValues = setFormikObjectInitialValues(textFieldProps, textFieldValue);
      setFormikInitialValues(objInitialValues);

      for (let i = 0; i < textFieldProps.length; i += 1)
        setFieldValue(textFieldProps[i], textFieldValue[i]);
    }
    setuserGroupSelect(userGroupID);
    setuserGroupList([
      { id: 1, description: '系統管理員' },
      { id: 2, description: '超級使用者' },
      { id: 3, description: '一般使用者' }
    ]);
  }, [props]);

  useEffect(() => {
    setUserEnable(enable);
    getTeamList();
  }, []);

  const onSubmit = async (values, actions) => {
    actions.setSubmitting(false);
    const today = new Date().toISOString();
    getTeamNameSelect();
    const userJson = createUserJson(values, today);
    const userRecord = createUserReacordJson(today);
    const userOperationRecordJson = createUserOperationRecordJson();

    const recordJson = {
      action: type,
      UserRecord: userRecord
    };
    // console.log(userOperationRecordJson);
    // console.log(userJson);
    // console.log(recordJson);
    // console.log();

    // // console.log(eventContext.current );

    if (eventContext.current !== '' && eventContext.current !== null) {
      updateUser(userOperationRecordJson, userJson, recordJson);
    } else if (userGroupID !== userGroupSelect)
      updateUser(userOperationRecordJson, userJson, recordJson);
    // if(eventContext.current !== null)
    //   updateUser(userOperationRecordJson,userJson,recordJson);
  };

  /* ***************************************
      操作紀錄-事件描述
      1.啟用tag或停用tag (停用也順勢解綁車輛)
      2.更改Tag所屬車隊
      3.身份證
      4.駕駛姓名
      5.email
      6.電話
      7.特別註記
      8.描述
  *************************************** */
  const getUserOperationEvent = (values) => {
    eventContext.current = null;
    // console.log(eventContext.current);

    const eventContextList = ['所屬車隊', '權限', '使用者名稱', 'email'];
    const objectNameList = [teamSelect, userGroupSelect, values.userName, values.email];
    const oldValuesList = [teamID, userGroupID, userName, email];

    const eventContextListOfInsert = ['使用者資訊'];
    const objectNameListOfInsert = [values.userName];
    const oldValuesListOfInsert = [userName];

    eventContext.current = getOperationEvent(
      userEnable,
      enable,
      type,
      eventContextList,
      objectNameList,
      oldValuesList,
      eventContextListOfInsert,
      objectNameListOfInsert,
      oldValuesListOfInsert
    );

    // console.log(eventContext.current);
  };
  // 操作紀錄的JSON
  const createUserOperationRecordJson = () => {
    const userOperationRecordJson = {
      action: type,
      type: 'User',
      description: eventContext.current
    };
    // // console.log(userOperationRecordJson);
    return userOperationRecordJson;
  };
  // 最新user資料的JSON
  const createUserJson = (values, date) => {
    getUserOperationEvent(values);
    // // console.log(eventContext);
    const jsonList = [];
    // 選擇停用(要解除綁定)
    // Select
    if (type === 'insert') {
      return {
        team_id: teamSelect,
        user_name: values.userName,
        email: values.email,
        user_group_id: userGroupSelect,
        enable: userEnable
      };
    }
    return {
      user_id: userID,
      team_id: teamSelect,
      user_name: values.userName,
      email: values.email,
      user_group_id: userGroupSelect,
      enable: userEnable
    };

    // // console.log(userJson);
    // return jsonList[0];
  };
  // 舊user資料的JSON
  const createUserReacordJson = (date) => {
    const userRecord = [];

    if (type === 'update')
      userRecord.push({
        user_id: userID,
        team_id: teamID,
        team_name: oldTeamName,
        // "identification":identification,
        user_name: userName,
        email: email,
        user_group_id: userGroupID,
        enable: enable
      });

    if (type === 'insert') {
      userRecord.push({
        team_id: teamSelect,
        team_name: teamNameSelect.current,
        // "identification":values.identification,
        user_name: values.userName,
        email: values.email,
        user_group_id: userGroupSelect,
        enable: userEnable
      });
    } else {
      userRecord.push({
        user_id: userID,
        team_id: teamSelect,
        team_name: teamNameSelect.current,
        // "identification":values.identification,
        user_name: values.userName,
        email: values.email,
        user_group_id: userGroupSelect,
        enable: userEnable
      });
    }

    // // console.log(oldUserJson);
    return userRecord;
  };

  const getTeamNameSelect = () => {
    for (let i = 0; i < teamList.length; i += 1) {
      if (teamList[i].id === teamSelect) {
        teamNameSelect.current = teamList[i].description;
        break;
      }
    }
  };

  const getTeamList = () => {
    // console.log(teams);
    const list = [];
    for (let i = 0; i < teams.length; i += 1) {
      list.push({ id: teams[i].id, description: teams[i].name });
      if (teams[i].id === teamID) setOldTeamName(teams[i].name);
    }
    setTeamList(list);

    for (let i = 0; i < userList.length; i += 1) {
      if (userList[i].id === userID) {
        setTeamSelect(userList[i].team_id);
        break;
      }
    }
  };

  // 更新資料
  const updateUser = async (userOperationRecordJson, userJson, recordJson) => {
    // console.log(values)
    await axios
      .post(
        `https://${ipconfig.apiIP}/api/v1/${type}/userInfo`,
        {
          token: users.token,
          userOperationRecordJson: userOperationRecordJson,
          userJson: userJson,
          recordJson: recordJson
        },
        {
          header: {
            Authorization: users.token,
            'Content-Type': 'application/json'
          }
        }
      )
      .then(async (response) => {
        dispatch({ type: 'CHANGE_TOKEN_STATUS', payload: response.data.resultCode });
        // eslint-disable-next-line eqeqeq
        if (response.data.resultCode == 'success') {
          dispatch(setAlertMessage({ open: true, severity: 'success', content: '成功' }));
          setValue(0);
          // // console.log("Success");
          // setSnackbarStateSuccess(true);
          dispatch(fetchUserList({ userName: users.userName, token: users.token }));
          localStorage.setItem('mode', '');
        }
      })
      .catch((error) => {
        // handle error
        // console.log(error);
      })
      .then(() => {
        // always executed
      });
  };

  const handleChangeUserEnable = (event) => {
    // 選停用user
    setUserEnable(parseInt(event.target.value, 10));
  };

  const handleClose = () => {
    setSnackbarStateFailed(false);
    setSnackbarStateSuccess(false);
  };

  return (
    <div>
      <FormikProvider value={formik}>
        <div>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              {(() => {
                if (users.permission === 'admin') {
                  return (
                    <SelectMenuItem
                      Option={teamList}
                      select={teamSelect}
                      setselect={setTeamSelect}
                      labelName={labelTeamName}
                    />
                  );
                }
              })()}
              {(() => {
                if (users.permission === 'admin') {
                  return (
                    <SelectMenuItem
                      Option={userGroupList}
                      select={userGroupSelect}
                      setselect={setuserGroupSelect}
                      labelName={labelUserGroup}
                    />
                  );
                }
              })()}
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={userEnable}
                onChange={handleChangeUserEnable}
              >
                <FormControlLabel value={1} control={<Radio />} label="啟用" />
                <FormControlLabel value={0} control={<Radio />} label="停用" />
              </RadioGroup>
              {/* <TextField
            fullWidth
            autoComplete="name"
            type="text"
            label="駕駛名稱"
            {...getFieldProps('name')}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />
          <TextField
            fullWidth
            autoComplete="identification"
            type="text"
            label="身份證"
            {...getFieldProps('identification')}
            error={Boolean(touched.identification && errors.identification)}
            helperText={touched.identification && errors.identification}
          />
          <TextField
            fullWidth
            autoComplete="phone"
            // type="text"
            label="手機"
            {...getFieldProps('phone')}
            type="number"
            InputProps={{ inputProps: { min: 0, max: 10 } }}
            error={Boolean(touched.phone && errors.phone)}
            helperText={touched.phone && errors.phone}
          />
          <TextField
            fullWidth
            autoComplete="email"
            type="text"
            label="email"
            {...getFieldProps('email')}
          />

          <TextField
            fullWidth
            autoComplete="specialMark"
            type="text"
            label="特殊註記"
            {...getFieldProps('specialMark')}
          />

          <TextField 
            fullWidth
            autoComplete="description"
            type="text"
            label="描述"
            {...getFieldProps('description')}
          /> */}

              {(() => {
                const TextFieldlist = [];
                for (let i = 0; i < textFieldLabel.length; i += 1) {
                  TextFieldlist.push(
                    <TTextField
                      name={textFieldProps[i]}
                      // type={}
                      label={textFieldLabel[i]}
                      getFieldProps={getFieldProps(textFieldProps[i])}
                      error={Boolean(touched[textFieldProps[i]] && errors[textFieldProps[i]])}
                      helperText={touched[textFieldProps[i]] && errors[textFieldProps[i]]}
                    />
                  );
                }
                return TextFieldlist;
              })()}
            </Stack>

            <Stack spacing={3} style={{ position: 'relative' }} sx={{ mt: 3 }}>
              <LoadingButton
                style={{ position: 'absolute', right: 0 }}
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                確認送出
              </LoadingButton>
            </Stack>
          </Form>
        </div>
        <div>
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            onClose={handleClose}
            message="輸入資料有誤，請重新輸入"
            key={vertical + horizontal}
          />
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open1}
            onClose={handleClose}
            message="修改完成"
            key1={vertical + horizontal}
          />
        </div>
      </FormikProvider>
    </div>
  );
}
