import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
// material
import { Button, Grid, Tabs, Tab, Typography, Box, Container, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Page from '../components/Page';
import BMap from '../components/BMap';
import { SimpleDialog } from '../components/dialog';

import { fetchReaderList } from '../actions';
import TTable from '../components/TTable';

import { ReaderForm } from '../components/editData/index';
import Tooltip from '../css/Tooltip.module.css';
import * as config from '../config/config';

const Alert = React.forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

// 定義切換頁
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    style: { 'font-size': '24px' }
  };
}

export default function EnhancedTable() {
  // 宣告一個新的 state 變數，我們稱作為「count」。
  // 相似於 componentDidMount 和 componentDidUpdate:

  const headCells = [
    {
      id: 'index', // 修改為 index
      numeric: false,
      disablePadding: true,
      fillter: false,
      label: '項次' // 修改標籤文字
    },
    {
      id: 'leonReaderName',
      numeric: false, // 改為 false 以便置中
      disablePadding: true,
      fillter: true,
      label: '智慧感知讀取器名稱',
      type: 'render',
      fn: (row) => {
        const { leonReaderName, latitude, longitude } = row;
        const color = '#00AB55';
        // 修改此處，不再拆分文字
        return (
          <button
            type="button"
            style={{
              color: color,
              cursor: 'pointer',
              textAlign: 'center',
              border: 'none',
              background: 'none',
              padding: 0,
              font: 'inherit',
              width: '100%'
            }}
            onClick={() => {
              Object.keys(openmap._layers).forEach((key) => {
                // console.log(key)
                // console.log(openmap._layers[key] )
                if (openmap._layers[key] !== undefined)
                  if (row.id === openmap._layers[key].options.id) {
                    openmap._layers[key].openPopup();
                  }
              });
              openmap.flyTo([latitude, longitude], 20);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                Object.keys(openmap._layers).forEach((key) => {
                  if (openmap._layers[key] !== undefined)
                    if (row.id === openmap._layers[key].options.id) {
                      openmap._layers[key].openPopup();
                    }
                });
                openmap.flyTo([latitude, longitude], 20);
              }
            }}
          >
            {leonReaderName}
          </button>
        );
      }
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: true,
      fillter: true,
      label: '連線狀態',
      type: 'format',
      fn: (enable) => {
        const enableStr = enable ? '連線' : '斷線';
        const color = enable ? 'green' : 'red';
        return <div style={{ color: color }}>{enableStr}</div>;
      }
    },
    {
      id: 'enable',
      numeric: false,
      disablePadding: true,
      fillter: true,
      label: '狀態',
      type: 'format',
      fn: (enable) => {
        const enableStr = enable ? '啟用' : '停用';
        return enableStr;
      }
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      fillter: true,
      label: '設備編號'
    },
    {
      // 排序id要跟資料欄一樣名稱
      id: 'leonReaderMACAddress',
      numeric: false,
      disablePadding: true,
      fillter: true,
      label: 'MACAddress'
    },
    // {
    //   id: 'address',
    //   numeric: true,
    //   disablePadding: true,
    //   fillter: true,
    //   label: '設置位置',
    //   type: 'render',
    //   fn: (row) => {
    //     const { address } = row;
    //     const words = address.split('(');
    //     if (Array.isArray(words)) {
    //       if (words.length > 1) words[1] = `(${words[1]}`;
    //     }
    //     if (words === undefined) return null;
    //     if (typeof words === 'string') return words;
    //     const children = words.map((val) => <div>{val}</div>);
    //     /* eslint-disable */
    //     return <div>{children}</div>;
    //   }
    // },
    // {
    //   id: 'description',
    //   numeric: false,
    //   disablePadding: true,
    //   fillter: true,
    //   label: '描述'
    // },
    {
      id: 'updateTime',
      numeric: false,
      disablePadding: true,
      fillter: false,
      label: '更新時間',
      type: 'format',
      fn: (data) => {
        const time = new Date(data).toLocaleString();
        return time;
      }
    },
    {
      type: 'render',
      fn: (row) => {
        const {
          leonReaderMACAddress,
          leonReaderName,
          name,
          latitude,
          longitude,
          address,
          description,
          updateTime,
          createTime,
          enable
        } = row;
        return (
          <Button
            variant="outlined"
            onClick={() => {
              let tempDescription = description;
              if (description === null) tempDescription = '';
              setInfo(
                row.id,
                leonReaderMACAddress,
                leonReaderName,
                name,
                latitude,
                longitude,
                address,
                tempDescription,
                updateTime,
                createTime,
                enable
              );
              // // console.log(leonTagID)
              // setValue(1)
            }}
          >
            編輯
          </Button>
        );
      }
    }
  ];

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('status');
  const [selected, setSelected] = React.useState([]);
  // 車機清單
  const [Vehiclelist, setVehiclelist] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filterName, setFilterName] = React.useState('');
  const [reason, setreason] = React.useState(' ');
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [marker, setMarker] = useState(null);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const dispatch = useDispatch();
  const usersStore = useSelector((state) => state.userLogin);
  const readerStore = useSelector((state) => state.readerManage);
  const { users } = usersStore;
  const { readers, error } = readerStore;
  const { teams } = useSelector((state) => state.teamsInfo);
  const teamsindex = teams.findIndex((team) => team.id === users.team_id);
  const teamName = teams[teamsindex]?.name;
  // eslint-disable-next-line prefer-const
  let intervalId = null;
  let map;

  const [first, setfirst] = useState(true);
  useEffect(() => {
    console.log('readers', readers);
    Vehicle_management();
    // 加入 index 到每一筆資料
    const readerWithIndex = readers.map((reader, idx) => ({
      ...reader,
      index: idx + 1
    }));
    setVehiclelist(readerWithIndex);
  }, [users]);

  useEffect(() => {
    // 加入 index 到每一筆資料
    const readerWithIndex = readers.map((reader, idx) => ({
      ...reader,
      index: idx + 1
    }));
    setVehiclelist(readerWithIndex);
  }, [readers]);

  const [mapcenter, setmapcenter] = useState([24, 121]);
  const [openmap, setopenmap] = useState(null);

  useEffect(() => {
    if (openmap != null) {
      const bounds = [
        [25.112698, 121.311192],
        [25.080091, 121.24397499999999]
      ];
      for (let i = 0; i < readers.length; i += 1) {
        if (readers[i].latitude !== null || readers[i].longitude !== null) {
          if (i === 0) {
            bounds[0][0] = readers[i].latitude;
            bounds[1][0] = readers[i].latitude;
            bounds[0][1] = readers[i].longitude;
            bounds[1][1] = readers[i].longitude;
          } else if (readers[i].latitude !== 0) {
            if (readers[i].latitude > bounds[0][0]) bounds[0][0] = readers[i].latitude;
            else if (readers[i].latitude < bounds[1][0]) bounds[1][0] = readers[i].latitude;
            if (readers[i].longitude > bounds[0][1]) bounds[0][1] = readers[i].longitude;
            else if (readers[i].longitude < bounds[1][1]) bounds[1][1] = readers[i].longitude;
          }
        }
      }
      bounds[0][0] += 0.001;
      bounds[0][1] += 0.001;
      bounds[1][0] -= 0.001;
      bounds[1][1] -= 0.001;
      openmap.fitBounds(bounds);
    }
  }, [readers, openmap]);

  // 圖標顯示內容
  const popupcontent = (row, textColor) => {
    const r = row;
    return (
      <div>
        <div className={Tooltip.text_root}>
          <div className="pb-4">
            <span className={Tooltip.text_name}>{row.address}</span>
          </div>
          <div className="pb-4">
            <span className={Tooltip.text_address}>智慧感知讀取器名稱 : {row.leonReaderName}</span>
          </div>
          <div className="pb-4">
            <span className={Tooltip.text_address}>設備編號 : {row.name}</span>
          </div>
        </div>
      </div>
    );
  };

  // 讀取車機清單
  // eslint-disable-next-line camelcase
  const Vehicle_management = async () => {
    dispatch(fetchReaderList({ userName: users.userName, token: users.token, teamName }));
  };

  // 關閉彈跳釋出
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  // tab切換
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [dialogOpen, setDialogOpen] = useState(false);
  const [driverID, setDriverID] = useState(0);
  const [truckDynamicID, setTruckDynamicID] = useState(0);
  const [type, setType] = useState();

  const [readerID, setReaderID] = useState(0);
  const [leonReaderMACAddress, setLeonReaderMACAddress] = useState();
  const [leonReaderName, setLeonReaderName] = useState();
  const [name, setName] = useState();
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [address, setAddress] = useState();
  const [description, setDescription] = useState();
  const [updateTime, setUpdateTime] = useState();
  const [createTime, setCreateTime] = useState();
  const [enable, setEnable] = useState(1);

  const setInfo = (
    readerID,
    leonReaderMACAddress,
    leonReaderName,
    name,
    latitude,
    longitude,
    address,
    description,
    updateTime,
    createTime,
    enable
  ) => {
    setReaderID(readerID);
    setLeonReaderMACAddress(leonReaderMACAddress);
    setLeonReaderName(leonReaderName);
    setName(name);
    setLatitude(latitude);
    setLongitude(longitude);
    setAddress(address);
    setDescription(description);
    setUpdateTime(updateTime);
    setCreateTime(createTime);
    setEnable(enable);
    setType('update');
    setValue(1);
  };

  const handleDialogClickOpen = (leonTagID, truckID, leonTagProductNumber, description) => {
    setDialogOpen(true);
    // setLeonTagID(leonTagID);
    // setDriverID(driverID);
    // setTruckID(truckID);
    // setTruckDynamicID(truckDynamicID);
  };

  const handleDialogClose = (value) => {
    setDialogOpen(false);
    setType(value);
    if (value !== '') setValue(1);
  };

  const insetLeonTagForm = () => {
    setReaderID('');
    setLeonReaderMACAddress('');
    setLeonReaderName('');
    setName('');
    setLatitude('');
    setLongitude('');
    setAddress('');
    setDescription('');
    setUpdateTime('');
    setCreateTime('');
    setEnable(1);
    setType('insert');
    setValue(1);
  };

  // Avoid a layout jump when reaching the last page with empty rows.

  return (
    <Page title="智慧管理監控平台">
      <Container maxWidth="xl">
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            {reason}
          </Alert>
        </Snackbar>

        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="智慧感知讀取器資訊" {...a11yProps(0)} />
          <Tab label="詳細資訊" {...a11yProps(1)} />
          <Button
            variant="contained"
            size="large"
            style={{ marginLeft: 'auto', marginRight: '24px' }}
            onClick={insetLeonTagForm}
          >
            新增
          </Button>
        </Tabs>

        <TabPanel value={value} index={0}>
          <Grid item xs={12} style={{ position: 'relative' }} mb={3}>
            <BMap
              mapcenter={mapcenter}
              setopenmap={setopenmap}
              openmap={openmap}
              MarkerArray={Vehiclelist}
              popupcontent={popupcontent}
              // ispopupline='true' linepopupcontent={linepopupcontent}
              // tablePopup={tablePopup}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              '& th': {
                fontSize: config.tableTitleFontSize
              },
              '& td': {
                fontSize: config.tableContentFontSize
              }
            }}
          >
            <TTable Vehiclelist={Vehiclelist} headCells={headCells} />
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <ReaderForm
            readerID={readerID}
            leonReaderMACAddress={leonReaderMACAddress}
            leonReaderName={leonReaderName}
            name={name}
            latitude={latitude}
            longitude={longitude}
            address={address}
            description={description}
            updateTime={updateTime}
            createTime={createTime}
            enable={enable}
            type={type}
            setValue={setValue}
          />
        </TabPanel>
        <SimpleDialog open={dialogOpen} onClose={handleDialogClose} />
      </Container>
    </Page>
  );
}
