/* eslint-disable no-else-return */
import httpClient from '../utils/httpClient';

export const getReaderList = async (data) => {
  try {
    const response = await httpClient.post('/reader/list', data);
    console.log('getReaderList:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching reader list:', error);
    throw error;
  }
};
// 在 readerService.js 中添加新函數
export const getDailyReaderStats = async (macAddress, date) => {
  try {
    const response = await httpClient.post('/reader/hourlystats', {
      date: date,
      macAddress: macAddress
    });

    if (response.data.success) {
      return response.data.data.map((hourData) => ({
        timeTag: `${String(hourData.hour).padStart(2, '0')}:00`,
        readers: {
          ETG小客車: hourData['ETG小客車'],
          LEON大車: hourData['LEON大車'],
          ETG大型車輛: hourData['ETG大型車輛']
        }
      }));
    }
    return [];
  } catch (error) {
    console.error('Error fetching daily reader stats:', error);
    return [];
  }
};
export const getRouteStats = async (date, routeId) => {
  try {
    const response = await httpClient.post('/trafficFlow/routestats', {
      date: date,
      routeId: routeId
    });

    if (response.data.success) {
      return response.data.data.map((hourData) => ({
        timeTag: `${String(hourData.hour).padStart(2, '0')}:00`,
        avg_speed: hourData.avg_speed,
        avg_travel_time_seconds: hourData.avg_travel_time_seconds
      }));
    }
    return [];
  } catch (error) {
    console.error('Error fetching route stats:', error);
    return [];
  }
};

export const getMonthlyEventAggregation = async (macAddress, month) => {
  try {
    const response = await httpClient.post('/reader/monthlystats', {
      month: month,
      macAddress: macAddress
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching monthly event aggregation:', error);
    throw error;
  }
};
